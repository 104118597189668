import { useState, useEffect, useCallback } from 'react';
import { useTheme } from 'styled-components';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

import { useAppDispatch } from '@/core/store/store';
import { ThemeVariant } from '@/core/interfaces/common';
import { Heading, Paragraph, Caption } from '@/core/components/Typography';
import { Icon } from '@/core/components/Icons';
import { Switch } from '@/core/components/Switch';
import { Button, ButtonVariant } from '@/core/components/Button';
import { MultiButton } from '@/core/components/MultiButton';
import { SelectComponent } from '@/core/components/Select';
import { TextField } from '@/core/components/TextField';
import { getAllAppliedFiltersSelector, resetFilters } from '@/core/store/reducers/filtersSlice';
import { DeleteModal } from '@/core/components/DeleteModal';
import { Modal } from '@/core/components/Modal';
import { HEADER_HEIGHT } from '@/core/components/Layout/Layout.styles';
import { EDIT_FOCUS_MODAL_Z_INDEX } from '@/core/constants/zIndex';
import { ShareInput } from '@/core/components/ShareInput';
import { GEOJSONData } from '@/core/interfaces/geojsons';
import { FilterType } from '@/core/interfaces/filters';

import {
  createFocus,
  selectFocus,
  updateFocus,
  deleteFocus,
  openFocusModal,
  focusModalSelector,
  getSelectedFocusSelector,
  saveDraftFocus,
  getDraftFocusSelector,
  clearDraftFocus,
} from '@/features/Focus/store';
import { EditFilters } from '@/features/Focus/components/EditFilters';
import { CreateFocusData, NotificationsTimeOptions } from '@/features/Focus/interfaces';
import {
  getGeofencesFromAppliedFilters,
  transformAppliedFiltersToFocusData,
} from '@/features/Focus/utils';

import {
  EditFocusContainer,
  EditFocusHeader,
  StyledSection,
  FilterSection,
  SwitchLabel,
  StyledInfo,
  WeeklyContainer,
  DailyContainer,
  FrequencyField,
  ButtonsContainer,
  StyledDeleteButton,
} from './FocusModal.styles';
import { FormData, FocusModalProps } from './FocusModal.types';

const getGeofencesToDelete = (
  originalGeofences: Array<GEOJSONData>,
  currentGeofences: Array<GEOJSONData>
): Array<string> => {
  const geofencesToDelete = originalGeofences
    .filter(
      originalGeofence =>
        !currentGeofences.some(
          currentGeofence => currentGeofence.properties.id === originalGeofence.properties.id
        )
    )
    .map(geofence => geofence.properties.id);

  return geofencesToDelete;
};

const getGeofencesToUpdate = (
  originalGeofences: Array<GEOJSONData>,
  currentGeofences: Array<GEOJSONData>
): Array<GEOJSONData> => {
  const geofencesToUpdate = currentGeofences.filter(currentGeofence =>
    originalGeofences.some(
      originalGeofence => originalGeofence.properties.id === currentGeofence.properties.id
    )
  );

  return geofencesToUpdate;
};

const getGeofencesToCreate = (
  originalGeofences: Array<GEOJSONData>,
  currentGeofences: Array<GEOJSONData>
): Array<GEOJSONData> => {
  const geofencesToCreate = currentGeofences.filter(
    currentGeofence =>
      !originalGeofences.some(
        originalGeofence => originalGeofence.properties.id === currentGeofence.properties.id
      )
  );

  return geofencesToCreate;
};

const tabsData = [
  {
    label: 'Real-time',
    value: NotificationsTimeOptions.REALTIME,
  },
  {
    label: 'Daily',
    value: NotificationsTimeOptions.DAILY,
  },
  {
    label: 'Weekly',
    value: NotificationsTimeOptions.WEEKLY,
  },
];

const schema = yup.object().shape({
  name: yup
    .string()
    .required('Focus name is required')
    .max(255, 'Focus name cannot exceed 255 characters'),
  description: yup.string().max(255, 'Description cannot exceed 255 characters'),
  emailNotifications: yup.boolean(),
  emailFrequency: yup
    .object()
    .shape({
      frequency: yup.string().nullable(),
      hour: yup.string().nullable(),
      day: yup.string().nullable(),
    })
    .nullable(),
});

const daysSelectOptions = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
].map(day => ({
  label: day,
  value: day,
}));

const defaultValuesData: FormData = {
  name: 'New focus',
  description: '',
  emailNotifications: false,
  coworkers: [],
  emailFrequency: undefined,
};

export const FocusModal = ({ isOpen }: FocusModalProps) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { colors, theme } = useTheme();
  const dispatch = useAppDispatch();

  const appliedFilters = useSelector(getAllAppliedFiltersSelector);
  const focusModalMode = useSelector(focusModalSelector);
  const currentFocus = useSelector(getSelectedFocusSelector);
  const draftFocus = useSelector(getDraftFocusSelector);

  const isInCreateMode = focusModalMode === 'create';
  const currentFocusId = currentFocus?.id || null;

  const formMethods = useForm<FormData>({
    defaultValues: currentFocus || draftFocus?.data || defaultValuesData,
    resolver: yupResolver(schema),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
    setValue,
  } = formMethods;

  const emailNotifications = watch('emailNotifications');
  const notificationFrequency = watch('emailFrequency.frequency');

  const closeEditFocus = useCallback(() => {
    // @ts-expect-error we are setting clear to force reset
    reset({ clear: true });

    dispatch(openFocusModal(null));
  }, [dispatch, reset]);

  useEffect(() => {
    if (isInCreateMode) {
      if (draftFocus && draftFocus.draftMode === 'create') {
        reset(draftFocus.data);
      } else {
        reset(defaultValuesData);
      }
    }
  }, [currentFocus, reset, isInCreateMode, draftFocus, currentFocusId]);

  useEffect(() => {
    if (!emailNotifications) {
      setValue('emailFrequency.frequency', null);
    } else if (!notificationFrequency) {
      setValue('emailFrequency.frequency', NotificationsTimeOptions.REALTIME);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailNotifications]);

  useEffect(() => {
    if (notificationFrequency === NotificationsTimeOptions.REALTIME) {
      setValue('emailFrequency.day', null);
      setValue('emailFrequency.hour', null);
    } else if (notificationFrequency === NotificationsTimeOptions.DAILY) {
      setValue('emailFrequency.day', null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationFrequency]);

  const onSubmit = async (data: FormData) => {
    const dataToSubmit: CreateFocusData = {
      name: data.name,
      description: data.description,
      users: data.coworkers.length ? data.coworkers?.map(coworker => coworker) : undefined,
      receiveEmail: !!data.emailNotifications,
      notificationPreference: data.emailNotifications
        ? data.emailFrequency?.frequency || null
        : null,
      dayOfTheWeek: data.emailNotifications ? data.emailFrequency?.day || null : null,
      timeOfNotification: data.emailNotifications ? data.emailFrequency?.hour || null : null,
      ...transformAppliedFiltersToFocusData(appliedFilters),
    };

    let focusId = currentFocusId;

    if (isInCreateMode) {
      const { focusData } = unwrapResult(await dispatch(createFocus(dataToSubmit)));

      focusId = focusData.id;
    } else {
      const currentFocusLocationGeofences =
        getGeofencesFromAppliedFilters(currentFocus?.filters?.[FilterType.LOCATION]) || [];

      unwrapResult(
        await dispatch(
          updateFocus({
            id: focusId!,
            focusData: dataToSubmit,
            geofencesToCreate: getGeofencesToCreate(
              currentFocusLocationGeofences,
              dataToSubmit.geofences || []
            ),
            geofencesToUpdate: getGeofencesToUpdate(
              currentFocusLocationGeofences,
              dataToSubmit.geofences || []
            ),
            geofencesToDelete: getGeofencesToDelete(
              currentFocusLocationGeofences,
              dataToSubmit.geofences || []
            ),
          })
        )
      );
    }

    dispatch(selectFocus(focusId));
    // @ts-expect-error we are setting clear to force reset
    reset({ clear: true });
    closeEditFocus();
  };

  const handleDeleteFocus = () => {
    if (!currentFocusId) return;

    dispatch(deleteFocus(currentFocusId));
    dispatch(resetFilters());
    dispatch(selectFocus(null));
    setIsDeleteModalOpen(false);
    dispatch(openFocusModal(null));
  };

  useEffect(() => {
    return () => {
      // @ts-expect-error clear is custom property to force reset
      if (watch().clear) {
        dispatch(clearDraftFocus());
      } else {
        dispatch(
          saveDraftFocus({
            focusId: focusModalMode === 'edit' ? currentFocusId! : undefined,
            data: watch(),
            draftMode: focusModalMode,
          })
        );
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeEditFocus}
      matchContentSize
      height={`calc(100vh - ${HEADER_HEIGHT})`}
      zIndex={EDIT_FOCUS_MODAL_Z_INDEX}
      overlayPosition={{
        top: HEADER_HEIGHT,
        right: '0',
        left: 'auto',
      }}
    >
      <EditFocusContainer>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <EditFocusHeader>
              <Heading type="h1">{isInCreateMode ? 'Create Focus' : 'Edit Focus'}</Heading>
              {!isInCreateMode && (
                <ButtonsContainer>
                  <StyledDeleteButton
                    type="button"
                    onClick={() => setIsDeleteModalOpen(true)}
                  >
                    <Icon
                      name="Trash"
                      color={colors.warning}
                    />
                    <Paragraph
                      type="normal"
                      color={colors.warning}
                    >
                      Delete focus
                    </Paragraph>
                  </StyledDeleteButton>
                </ButtonsContainer>
              )}
            </EditFocusHeader>
            <StyledSection>
              <Heading type="h3">Focus details</Heading>
              <Paragraph
                type="normal"
                color={theme === ThemeVariant.LIGHT ? colors.primaryText : colors.secondaryText}
              >
                Name
              </Paragraph>
              <TextField
                name="name"
                placeholder="My focus"
              />
              <Paragraph
                type="normal"
                color={theme === ThemeVariant.LIGHT ? colors.primaryText : colors.secondaryText}
              >
                Description
              </Paragraph>
              <TextField
                name="description"
                placeholder="Here’s a quick description of this focus."
                autoComplete="off"
              />
              {errors.description && (
                <Paragraph
                  type="small-normal"
                  color={colors.warning}
                >
                  {errors.description.message}
                </Paragraph>
              )}
            </StyledSection>
            <StyledSection>
              <Heading type="h3">Email notifications</Heading>
              <Paragraph
                type="normal"
                color={colors.secondaryText}
              >
                Receive email notifications when there’s an update to this focus
              </Paragraph>
              <Controller
                control={control}
                name="emailNotifications"
                defaultValue={false}
                render={({ field }) => (
                  <>
                    <SwitchLabel>
                      <Switch
                        isChecked={field.value || false}
                        onChange={() => field.onChange(!field.value)}
                      />
                      <Caption
                        isBold
                        color={field.value ? colors.primaryText : colors.secondaryText}
                      >
                        RECEIVE EMAIL NOTIFICATIONS ABOUT THIS FOCUS
                      </Caption>
                    </SwitchLabel>
                  </>
                )}
              />
              <Controller
                control={control}
                name="emailFrequency.frequency"
                render={({ field: { value, onChange } }) => (
                  <MultiButton
                    isSmall
                    isDisabled={!emailNotifications}
                    tabsData={tabsData}
                    onChange={onChange}
                    active={value}
                  />
                )}
              />
              {emailNotifications && notificationFrequency === NotificationsTimeOptions.DAILY && (
                <DailyContainer>
                  <FrequencyField>
                    <Paragraph
                      type="normal"
                      color={colors.secondaryText}
                    >
                      Time of notification
                    </Paragraph>
                    <TextField
                      name="emailFrequency.hour"
                      type="time"
                    />
                  </FrequencyField>
                </DailyContainer>
              )}
              {emailNotifications && notificationFrequency === NotificationsTimeOptions.WEEKLY && (
                <WeeklyContainer>
                  <FrequencyField>
                    <Paragraph
                      type="normal"
                      color={colors.secondaryText}
                    >
                      Time of notification
                    </Paragraph>
                    <TextField
                      name="emailFrequency.hour"
                      type="time"
                    />
                  </FrequencyField>
                  <FrequencyField>
                    <Paragraph
                      type="normal"
                      color={colors.secondaryText}
                    >
                      Day
                    </Paragraph>
                    <Controller
                      control={control}
                      name="emailFrequency.day"
                      render={({ field: { value, onChange } }) => (
                        <SelectComponent
                          options={daysSelectOptions}
                          value={daysSelectOptions.find(day => day.value === value)}
                          onChange={selectValue => onChange(selectValue?.value)}
                        />
                      )}
                    />
                  </FrequencyField>
                </WeeklyContainer>
              )}
              <StyledInfo>
                <Icon
                  name="CircleInfo"
                  color={colors.secondaryText}
                />
                <Paragraph
                  type="small-normal"
                  color={colors.secondaryText}
                >
                  {emailNotifications
                    ? 'You will receive email notifications every time there’s new update about this focus.'
                    : 'You will NOT receive email notifications.'}
                </Paragraph>
              </StyledInfo>
            </StyledSection>
            <StyledSection>
              <Paragraph type="normal">Share focus</Paragraph>
              <Controller
                control={control}
                name="coworkers"
                render={({ field: { value, onChange } }) => {
                  const coworkersValue = value || [];

                  return (
                    <ShareInput
                      onSelect={person => onChange([...coworkersValue, person])}
                      selectedCoworkersIds={coworkersValue}
                      handleRemoveSelectedCoworker={coworkerId =>
                        onChange(coworkersValue.filter(coworker => coworker !== coworkerId))
                      }
                    />
                  );
                }}
              />
            </StyledSection>
            <FilterSection>
              <EditFilters />
            </FilterSection>
            <ButtonsContainer>
              <Button type="submit">SAVE CHANGES</Button>
              <Button
                type="button"
                variant={ButtonVariant.TERTIARY}
                onClick={closeEditFocus}
              >
                CANCEL
              </Button>
            </ButtonsContainer>
          </form>
        </FormProvider>
        <DeleteModal
          isOpen={isDeleteModalOpen}
          title={`Delete '${currentFocus?.name}'?`}
          content={
            <Paragraph type="normal">
              Caution: Deleting this focus will also remove it from other users it is shared with.
            </Paragraph>
          }
          confirmButtonText="YES, DELETE"
          onClose={() => setIsDeleteModalOpen(false)}
          onDelete={handleDeleteFocus}
        />
      </EditFocusContainer>
    </Modal>
  );
};
