import { useCallback } from 'react';
import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';

import { Paragraph } from '@/core/components/Typography';
import { Icon } from '@/core/components/Icons';
import { ThemeVariant } from '@/core/interfaces/common';
import { FilterType } from '@/core/interfaces/filters';
import {
  openFilter,
  getAllAppliedFiltersSelector,
  resetFilters,
} from '@/core/store/reducers/filtersSlice';
import { useAppDispatch } from '@/core/store/store';

import { openFocusModal } from '@/features/Focus/store';

import {
  StyledFilterButton,
  StyledContainer,
  ClearButton,
  FilterAmountBadge,
} from './EditFilters.styles';

export const filters = [
  {
    label: 'Category',
    value: FilterType.CATEGORY,
  },
  {
    label: 'Location',
    value: FilterType.LOCATION,
  },
  {
    label: 'Keywords',
    value: FilterType.KEYWORDS,
  },
  {
    label: 'More filters',
    value: FilterType.MORE_FILTERS,
  },
  {
    label: 'Timeframe',
    value: FilterType.TIMEFRAME,
  },
];

export const EditFilters = () => {
  const { colors, theme } = useTheme();
  const dispatch = useAppDispatch();

  const appliedFilters = useSelector(getAllAppliedFiltersSelector);

  const handleClearFilters = useCallback(() => {
    dispatch(resetFilters());
    dispatch(openFilter(null));
  }, [dispatch]);

  const handleOnFilterClick = useCallback(
    (filterType: FilterType) => {
      dispatch(openFocusModal(null));
      dispatch(openFilter(filterType));
    },
    [dispatch]
  );

  return (
    <>
      <Paragraph type="normal">Edit Filters</Paragraph>
      <StyledContainer>
        {filters.map(({ label, value }) => {
          const hasAppliedFilters = (appliedFilters[value] || []).length > 0;

          return (
            <StyledFilterButton
              key={value}
              onClick={() => handleOnFilterClick(value)}
              isActive={hasAppliedFilters}
            >
              {label}
              {hasAppliedFilters && (
                <FilterAmountBadge>{appliedFilters[value]?.length}</FilterAmountBadge>
              )}
            </StyledFilterButton>
          );
        })}
        <ClearButton onClick={handleClearFilters}>
          Clear filters
          <Icon
            name="Close"
            color={theme === ThemeVariant.DARK ? colors.secondaryText : colors.teal}
          />
        </ClearButton>
      </StyledContainer>
    </>
  );
};
