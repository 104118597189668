import { ChangeEvent, useCallback, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '@/core/store/store';
import { Input } from '@/core/components/Input';
import { Icon } from '@/core/components/Icons';
import { applyAllFilters } from '@/core/store/reducers/filtersSlice';
import { FocusItem } from '@/core/components/FocusItem/FocusItem';

import { getSelectedFocusSelector, selectFocus } from '@/features/Focus/store';

import { FocusesContainer } from './FocusesList.styles';
import { FocusesListProps } from './FocusesList.types';

export const FocusesList = ({ focuses }: FocusesListProps) => {
  const [availableFocuses, setAvailableFocuses] = useState(focuses);
  const [searchValue, setSearchValue] = useState('');
  const dispatch = useAppDispatch();
  const selectedFocus = useSelector(getSelectedFocusSelector);

  const onSearch = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const searchText = event.target.value;

      setSearchValue(searchText);

      const filteredFocuses = focuses.filter(focus =>
        focus.name.toLowerCase().includes(event.target.value.toLowerCase())
      );

      setAvailableFocuses(filteredFocuses);
    },
    [focuses]
  );

  useEffect(() => {
    setAvailableFocuses(focuses);
  }, [focuses]);

  const handleSelectFocus = useCallback(
    (id: number) => {
      dispatch(selectFocus(id));

      const newSelectedFocus = focuses.find(focus => focus.id === id);

      if (newSelectedFocus?.filters) {
        dispatch(applyAllFilters(newSelectedFocus.filters));
      } else {
        dispatch(applyAllFilters({}));
      }
    },
    [dispatch, focuses]
  );

  return (
    <FocusesContainer>
      <Input
        placeholder="Search focuses"
        value={searchValue}
        onChange={onSearch}
        prefixIcon={<Icon name="Search" />}
      />
      {availableFocuses.map(focus => (
        <FocusItem
          isSelected={selectedFocus?.id === focus.id}
          name={focus.name}
          key={focus.id}
          onClick={() => handleSelectFocus(focus.id)}
        />
      ))}
    </FocusesContainer>
  );
};
