import React, { ReactNode, useCallback, useEffect, useMemo } from 'react';
import NextImage from 'next/image';
import NextLink from 'next/link';
import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import isEqual from 'lodash/isEqual';

import { useAppDispatch } from '@/core/store/store';
import {
  Main,
  PageAsideMenu,
  PageHeader,
  PageHeaderLogo,
  PageContent,
  ButtonsContainer,
  StyledButton,
} from '@/core/components/Layout/Layout.styles';
import { MainMenu } from '@/core/components/Layout/MainMenu';
import { ThemeVariant } from '@/core/interfaces/common';
import { MenuSection } from '@/core/components/MenuSection';
import { FocusesList } from '@/core/components/FocusesList';
import { ButtonVariant } from '@/core/components/Button';
import { TimeframeFilter } from '@/core/components/MapFilters/TimeframeFilter';
import { timeframeRadioButtonData } from '@/core/components/MapFilters/TimeframeFilter/timeframeRadioButtonData';
import { CategoryFilter } from '@/core/components/MapFilters/CategoryFilter';
import { LocationFilter } from '@/core/components/MapFilters/LocationFilter';
import { KeywordsFilter } from '@/core/components/MapFilters/KeywordFilter';
import { MoreFilters } from '@/core/components/MapFilters/MoreFilters';
import { getAllAppliedFiltersSelector } from '@/core/store/reducers/filtersSlice';
import { ResetFiltersButton } from '@/core/components/ResetFiltersButton';
import { DEFAULT_FILTERS_DATA } from '@/core/constants/filters';

import {
  iFocusModalOpenSelector,
  getMyFocusesSelector,
  getSharedFocusesSelector,
  openFocusModal,
  getSelectedFocusSelector,
} from '@/features/Focus/store';
import { ReportDetails } from '@/features/Reports/ReportDetails';
import { FocusModal } from '@/features/Focus/FocusModal';
import { GeofeatureModal } from '@/features/Geofeatures/components/GeofeatureModal/GeofeatureModal';
import { closeGeofeatureModal, isGeofeatureModalOpenSelector } from '@/features/Geofeatures/store';
import { closeDetailsModal, isReportDetailsOpenSelector } from '@/features/Reports/store';

interface LayoutProps {
  children: ReactNode;
  shouldHideMainSidebar?: boolean;
}

export const Layout = ({ children, shouldHideMainSidebar = false }: LayoutProps) => {
  const { theme } = useTheme();
  const isDetailsOpen = useSelector(isReportDetailsOpenSelector);
  const focuses = useSelector(getMyFocusesSelector);
  const sharedFocuses = useSelector(getSharedFocusesSelector);
  const appliedFilters = useSelector(getAllAppliedFiltersSelector);
  const isGeofeatureOpen = useSelector(isGeofeatureModalOpenSelector);
  const isFocusModalOpen = useSelector(iFocusModalOpenSelector);
  const currentFocus = useSelector(getSelectedFocusSelector);
  const selectedFocusId = currentFocus ? currentFocus.id : null;

  const { events } = useRouter();

  const dispatch = useAppDispatch();

  const areFiltersApplied = Object.values(appliedFilters).some(array => array.length > 0);

  const handleUpdateFocus = useCallback(() => {
    if (!currentFocus) return;

    dispatch(openFocusModal('edit'));
  }, [dispatch, currentFocus]);

  const isFocusDifferent = useMemo(
    () => !isEqual(appliedFilters, currentFocus?.filters || DEFAULT_FILTERS_DATA),
    [appliedFilters, currentFocus?.filters]
  );

  useEffect(() => {
    const onRouteChange = () => {
      dispatch(closeDetailsModal());
      dispatch(openFocusModal(null));
      dispatch(closeGeofeatureModal());
    };

    events.on('routeChangeStart', onRouteChange);

    return () => {
      events.off('routeChangeStart', onRouteChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const onRouteChange = () => {
      dispatch(closeDetailsModal());
      dispatch(openFocusModal(null));
      dispatch(closeGeofeatureModal());
    };

    events.on('routeChangeStart', onRouteChange);

    return () => {
      events.off('routeChangeStart', onRouteChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageHeader>
        <PageHeaderLogo>
          {theme === ThemeVariant.DARK ? (
            <NextLink href="/">
              <NextImage
                src="/assets/logo-dark.svg"
                alt="Dark App Logo"
                width={140}
                height={42}
              />
            </NextLink>
          ) : (
            <NextLink href="/">
              <NextImage
                src="/assets/logo-light.svg"
                alt="Light App Logo"
                width={140}
                height={42}
              />
            </NextLink>
          )}
        </PageHeaderLogo>
        <MainMenu />
      </PageHeader>
      <Main isFullWidth={shouldHideMainSidebar}>
        {!shouldHideMainSidebar && (
          <PageAsideMenu>
            <ResetFiltersButton />
            <MenuSection
              isInitialyExpanded
              sectionName="My focuses"
            >
              <FocusesList
                focuses={focuses}
                selectedFocusId={selectedFocusId}
              />
            </MenuSection>
            <MenuSection sectionName="Shared focuses">
              <FocusesList
                focuses={sharedFocuses}
                selectedFocusId={selectedFocusId}
              />
            </MenuSection>
            <MenuSection sectionName="Filters">
              <LocationFilter />
              <CategoryFilter />
              <TimeframeFilter timeframeFiltersData={timeframeRadioButtonData} />
              <KeywordsFilter />
              <MoreFilters />
            </MenuSection>
            {areFiltersApplied && (
              <ButtonsContainer>
                {isFocusDifferent && (
                  <>
                    {currentFocus && (
                      <StyledButton onClick={handleUpdateFocus}>UPDATE FOCUS</StyledButton>
                    )}

                    <StyledButton
                      variant={currentFocus ? ButtonVariant.SECONDARY : ButtonVariant.PRIMARY}
                      onClick={() => dispatch(openFocusModal('create'))}
                    >
                      SAVE FOCUS
                    </StyledButton>
                  </>
                )}
              </ButtonsContainer>
            )}
          </PageAsideMenu>
        )}
        <PageContent>
          {children}
          <ReportDetails isOpen={isDetailsOpen} />
          {isFocusModalOpen && (
            <FocusModal
              key={selectedFocusId}
              isOpen={isFocusModalOpen}
            />
          )}
          <GeofeatureModal isOpen={isGeofeatureOpen} />
        </PageContent>
      </Main>
    </>
  );
};
