import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';

import { RootState } from '@/core/interfaces/store';
import { CategoriesResponse } from '@/core/interfaces/categories';
import { CountriesResponse } from '@/core/interfaces/countries';

import { FocusAlertItem, FocusesState, FocusResponseItem } from '@/features/Focus/interfaces';
import {
  createFocus,
  deleteFocus,
  getFocusesList,
  getSharedFocusesList,
  updateFocus,
} from '@/features/Focus/store';
import { transformFocusDataToAppliedFilters } from '@/features/Focus/utils';

const initialState: FocusesState = {
  selectedFocus: null,
  focuses: [],
  sharedFocuses: [],
  focusModal: null,
  draftFocus: null,
};

const mapFocusDataToFocus = (
  focusData: FocusResponseItem,
  {
    alerts = [],
    categories,
    countries,
  }: {
    alerts?: Array<FocusAlertItem>;
    categories: CategoriesResponse;
    countries: CountriesResponse;
  }
) => {
  const focusAlert = alerts.find(alert => alert.focus === focusData.id);

  return {
    id: focusData.id,
    name: focusData.name,
    description: focusData.description,
    filters: transformFocusDataToAppliedFilters({
      focusData,
      categories,
      countries,
    }),
    emailNotifications: !!focusAlert?.receiveEmail,
    emailFrequency: focusAlert?.receiveEmail
      ? {
          day: focusAlert.dayOfTheWeek,
          frequency: focusAlert.notificationPreference,
          hour: focusAlert.timeOfNotification,
        }
      : undefined,
    coworkers: focusData.users,
  };
};

export const focusesSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    openFocusModal: (state, { payload }: PayloadAction<'edit' | 'create' | null>) => {
      state.focusModal = payload;
    },
    selectFocus: (state, { payload }: PayloadAction<number | null>) => {
      const focusId = payload;

      const foundFocus =
        state.focuses.find(focus => focus.id === focusId) ||
        state.sharedFocuses.find(focus => focus.id === focusId);

      state.selectedFocus = foundFocus || null;
    },
    saveDraftFocus: (state, { payload }: PayloadAction<FocusesState['draftFocus']>) => {
      state.draftFocus = payload;
    },
    clearDraftFocus: state => {
      state.draftFocus = null;
    },
  },
  extraReducers: builder =>
    builder
      .addCase(
        createFocus.fulfilled,
        (state, { payload: { focusData, alerts, categories, countries } }) => {
          state.focuses = [
            ...state.focuses,
            mapFocusDataToFocus(focusData, {
              alerts,
              categories,
              countries,
            }),
          ];
        }
      )
      .addCase(
        updateFocus.fulfilled,
        (state, { payload: { focusData, alerts, categories, countries } }) => {
          state.focuses = state.focuses.map(focus => {
            if (focus.id === focusData.id) {
              return mapFocusDataToFocus(focusData, {
                alerts,
                categories,
                countries,
              });
            }

            return focus;
          });
        }
      )
      .addCase(deleteFocus.fulfilled, (state, { payload }) => {
        state.focuses = state.focuses.filter(focus => focus.id !== payload);
      })
      .addCase(
        getFocusesList.fulfilled,
        (state, { payload: { alerts, categories, countries, focuses } }) => {
          state.focuses = focuses.map(focus =>
            mapFocusDataToFocus(focus, {
              alerts,
              categories,
              countries,
            })
          );
        }
      )
      .addCase(
        getSharedFocusesList.fulfilled,
        (state, { payload: { alerts, categories, countries, focuses } }) => {
          state.sharedFocuses = focuses.map(focus =>
            mapFocusDataToFocus(focus, {
              alerts,
              categories,
              countries,
            })
          );
        }
      ),
});

export const { selectFocus, openFocusModal, saveDraftFocus, clearDraftFocus } =
  focusesSlice.actions;

const focusesSelector = (state: RootState) => state.focuses;

export const getMyFocusesSelector = createSelector(
  [focusesSelector],
  focusesState => focusesState.focuses
);
export const getSharedFocusesSelector = createSelector(
  [focusesSelector],
  focusesState => focusesState.sharedFocuses
);
export const getAllFocusesSelector = createSelector(focusesSelector, state => [...state.focuses]);
export const getSelectedFocusSelector = createSelector(
  focusesSelector,
  state => state.selectedFocus
);

export const focusModalSelector = createSelector(focusesSelector, state => state.focusModal);

export const iFocusModalOpenSelector = createSelector(
  focusModalSelector,
  state => state === 'edit' || state === 'create'
);

export const getDraftFocusSelector = createSelector(focusesSelector, state => state.draftFocus);

export default focusesSlice.reducer;
